import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { CKEditor } from 'ckeditor4-react';
import ImportSiteModalComponent from './ImportSiteModalComponent/ImportSiteModalComponent';
import { createLandingPage, editLandingPage } from '../../../apis/landingPages.ts';
import { useToast } from '../../../context/ToastContext';

const capturePasswordsMessage = (
  <div className="p-message p-component p-message-warn p-message-enter-done ml-4" role="alert" aria-live="assertive" aria-atomic="true" data-pc-name="messages" data-pc-section="root">
    <div className="p-message-wrapper" data-pc-section="wrapper">
      <span className="pi pi-info-circle" style={{ fontSize: '1.5rem', verticalAlign: 'middle', marginRight: '0.5rem' }} />
      <span className="p-message-summary vertical-align-middle" data-pc-section="summary">Warning</span>
      <span className="p-message-detail vertical-align-middle" data-pc-section="detail">
        Credentials are currently
        {' '}
        <b>
          not encrypted
        </b>
        . This means that captured passwords are stored in the database as clear text.
        Be careful with this!
      </span>
    </div>
  </div>
);

function ManageLandingPageFormComponent({
  landingPage, mode, setVisible,
}) {
  const [visibleImportSite, setVisibleImportSite] = useState(false);
  const [captureSubmitedData, setCaptureSubmitedData] = useState(false);
  const [importSite, setImportSite] = useState();
  const [initHtmlData, setInitHtmlData] = useState(landingPage?.html);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'Landing page name must be at least 2 characters').max(255, 'Landing page name must be at most 255 characters').required('Landing page name is required'),
  });

  const defaultValues = {
    name: '',
    shared: false,
    html: '',
    capture_credentials: false,
    capture_passwords: false,
    redirect_url: '',
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
    setValue,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (landingPage) {
      setCaptureSubmitedData(landingPage.capture_credentials);
      setInitHtmlData(landingPage.html);
      setValue('html', landingPage.html);
      reset({
        name: mode === 'edit' ? landingPage.name : `Copy of ${landingPage.name}`,
        html: landingPage.html,
        shared: landingPage.shared,
        capture_credentials: landingPage.capture_credentials,
        capture_passwords: landingPage.capture_passwords,
        redirect_url: landingPage.redirect_url,
      });
    }
  }, [landingPage]);

  useEffect(() => {
    if (importSite) {
      setInitHtmlData(importSite.html);
      setValue('html', importSite.html);
    }
  }, [importSite]);

  async function onSubmit() {
    setIsSubmitting(true);
    const data = { ...getValues() };
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    let response = {};
    if (mode === 'edit') {
      response = await editLandingPage({ ...sanitizedSendData, id: landingPage.id });
    } else {
      response = await createLandingPage({ ...sanitizedSendData });
    }
    if (response.response?.data.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
      });
    } else {
      setVisible(false);
      toast.current.show({
        severity: 'success', summary: 'Success', detail: mode === 'edit' ? 'Landing page edited' : 'Landing page created', life: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  }
  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    <>
      <ImportSiteModalComponent
        visible={visibleImportSite}
        setVisible={setVisibleImportSite}
        setImportSite={setImportSite}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Name*
                </label>
                <InputText
                  id={field.name}
                  placeholder="Page name"
                  value={field.value}
                  className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                  onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                  aria-describedby={`${field.name}-info`}
                />
                <div className="mt-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <div className="mb-5">
            <Controller
              name="shared"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name} className="block">
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2"
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                  />
                  Share landing page
                  <Tooltip target=".custom-target-icon" className="w-3" />
                  <i
                    className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                    data-pr-tooltip="If you share the landing page, it will be accessible for use by other users associated with your account."
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ cursor: 'pointer' }}
                  />
                </label>
              )}
            />
          </div>
          <div>
            <Button type="button" icon="pi pi-globe" className="mb-4 gap-2" onClick={() => setVisibleImportSite(true)}>Import Site</Button>
          </div>
          <Controller
            name="html"
            control={control}
            render={({ field }) => (
              <div>
                <CKEditor
                  key={initHtmlData}
                  initData={initHtmlData}
                  data={field.value}
                  editorUrl="./ckeditor/ckeditor.js"
                  config={{
                    startupMode: 'wysiwyg',
                  }}
                  onChange={(data) => { field.onChange(data.editor.getData()); }}
                  onBlur={(data) => { field.onChange(data.editor.getData()); }}
                  // Hack for solve Source mode lack of sync
                  onKey={(evt) => {
                    setTimeout(() => {
                      field.onChange(evt.editor.getData());
                    }, 0);
                  }}
                />
              </div>
            )}
          />
          <div className="mb-4">
            <Controller
              name="capture_credentials"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name} className="block">
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2 mt-4"
                    onChange={(e) => {
                      field.onChange(e.checked); setCaptureSubmitedData(e.checked);
                    }}
                  />
                  Capture Submitted Data
                  <Tooltip target=".custom-target-icon" className="w-3" />
                  <i
                    className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                    data-pr-tooltip="If the landing page contains a form, submitted input (except passwords!) will be captured."
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ cursor: 'pointer' }}
                  />
                </label>
              )}
            />
          </div>
          {captureSubmitedData && (
            <>
              <Controller
                name="capture_passwords"
                control={control}
                render={({ field }) => (
                  <label htmlFor={field.name}>
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => field.onChange(e.checked)}
                    />
                    Capture Passwords
                  </label>
                )}
              />
              {capturePasswordsMessage}
              <Controller
                name="redirect_url"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Redirect to
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="This option lets you redirect the user to a page after credentials are submitted."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        style={{ cursor: 'pointer' }}
                      />
                      <InputText
                        id={field.name}
                        placeholder="http://example.com"
                        value={field.value}
                        className="w-full"
                        onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                        aria-describedby={`${field.name}-info`}
                      />
                    </label>
                  </div>
                )}
              />
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
            <Button
              label={isSubmitting ? 'Saving...' : 'Save'}
              icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
              disabled={!isValid || isSubmitting}
              type="button"
              onClick={() => onSubmit()}
              autoFocus
            />
          </div>
        </div>
      </form>
    </>
  );
}

ManageLandingPageFormComponent.propTypes = {
  landingPage: PropTypes.object,
  mode: PropTypes.string,
  setVisible: PropTypes.func.isRequired,
};

ManageLandingPageFormComponent.defaultProps = {
  landingPage: {},
  mode: null,
};

export default ManageLandingPageFormComponent;

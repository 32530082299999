import { Tag } from 'primereact/tag';

export function formatDate(dateString) {
  if (dateString.startsWith('0001')) {
    return '-';
  }
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  return date.toLocaleDateString('en-ES', options);
}

export const RelationshipType = Object.freeze({
  ME: 'me',
  ANCESTOR: 'ancestor',
  DESCENDANT: 'descendant',
  EXTERNAL: 'external',
});

const ownerTypes = {
  me: {
    label: 'Me',
    color: 'info',
  },
  inherited: {
    label: 'Inherited',
    color: 'success',
  },
};

export const ownerInfo = (rowData) => {
  const owner = rowData.belongs_to === RelationshipType.ME ? RelationshipType.ME : 'inherited';
  return (
    <Tag
      value={ownerTypes[owner].label}
      severity={ownerTypes[owner].color}
    />
  );
};

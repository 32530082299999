/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Divider } from 'primereact/divider';
import PropTypes from 'prop-types';
import { ListBox } from 'primereact/listbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import { templateTypes, difficultyOptions } from '../../utils';
import './CampaignResumeComponent.scss';

function calculateTotalTargets(groups) {
  const total = groups.map((group) => group.num_targets).reduce((acc, current) => acc + current, 0);
  return total;
}

const formatDateTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString();
};

function GroupListItem({ option }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <div>{option.name}</div>
        <small>
          {option.num_targets}
          {' '}
          targets
        </small>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <small style={{ marginRight: '4px', marginBottom: '0.5rem' }}>
          {formatDateTime(option.modified_date)}
        </small>
        <Tag severity="danger">{option.shared ? 'Shared' : 'Private'}</Tag>
      </div>
    </div>
  );
}

GroupListItem.propTypes = {
  option: PropTypes.object,
};

GroupListItem.defaultProps = {
  option: null,
};

function CampaignResumeComponent({ campaignInfo }) {
  const templateType = campaignInfo.sendingProfile.interface_type === 'SMS Gateway' ? 'SMS' : 'Email';
  const campaignTypeName = campaignInfo.sendingProfile.interface_type === 'SMS Gateway' ? 'Smishing Campaing' : 'Email Phishing Campaign';
  const diff = difficultyOptions.find((op) => op.value === campaignInfo?.difficulty);

  return (
    <div className="campaign-resume">
      <h1 style={{ marginTop: '-20px' }}>{campaignInfo.name}</h1>
      <div className="tag-container">
        {campaignInfo.test_type && (
          <Tag
            value="Test"
            severity="success"
            className="mt-3 mb-3 mr-3"
          />
        )}
        <Tag
          value={campaignTypeName}
          icon={templateTypes[templateType].icon}
          severity={templateTypes[templateType].color}
          className="mt-3 mb-3"
        />
        <Tag
          value={diff?.name}
          style={{ backgroundColor: diff.color }}
          className="mt-3 mb-3 ml-auto"
        />
      </div>
      <Accordion>
        <AccordionTab header={`Template: ${campaignInfo.template.name}`}>
          <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
          <ul className="inline-list">
            <li key="shared-template" style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared template:</label>
              <span>{campaignInfo.template.shared ? 'Yes' : 'No'}</span>
            </li>
            {templateType === 'SMS' ? (
              <li key="sender_id" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Sender ID:</label>
                <span>{campaignInfo.template.sms_sender_id}</span>
              </li>
            ) : (
              <>
                <li key="envelope_sender" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Envelope sender:</label>
                  <span>{campaignInfo.template.envelope_sender}</span>
                </li>
                <li key="subject" style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Email subject:</label>
                  <span>{campaignInfo.template.subject}</span>
                </li>
                <li key="attachments">
                  <label style={{ fontWeight: 'bold' }}>Attachments:</label>
                  {' '}
                  {campaignInfo.template.attachments?.length > 0 ? (
                    <ul>
                      {campaignInfo.template.attachments.map((attachment) => (
                        <li key={`${attachment.name}-${attachment.type}`}>
                          {attachment.name}
                          {' '}
                          (
                          {attachment.type}
                          )
                        </li>
                      ))}
                    </ul>
                  ) : 'No attachments'}
                </li>
              </>
            )}
            <li key="campaign_url" style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Redirection to URL:</label>
              <a href={campaignInfo.url} target="_blank" rel="noopener noreferrer">{campaignInfo.url}</a>
            </li>
          </ul>
          {templateType === 'Email' ? (
            <TabView style={{ padding: 0 }}>
              <TabPanel header="HTML">
                <div
                  style={{ maxHeight: '500px', overflowY: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: campaignInfo.template.html }}
                />
              </TabPanel>
              <TabPanel header="Text">
                <div
                  style={{ maxHeight: '500px', overflowY: 'auto' }}
                >
                  {campaignInfo.template.text}
                </div>
              </TabPanel>
            </TabView>
          ) : (
            <>
              <Divider align="center">
                <span className="p-tag">Text</span>
              </Divider>
              <div
                style={{ maxHeight: '500px', overflowY: 'auto' }}
              >
                {campaignInfo.template.text.replace(/{{\.URL}}/g, campaignInfo.url)}
              </div>
            </>
          )}
        </AccordionTab>
        <AccordionTab header={`Landing Page: ${campaignInfo.page.name}`}>
          <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
          <ul>
            <li key="shared-page" style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared landing page:</label>
              <span>{campaignInfo.page.shared ? 'Yes' : 'No'}</span>
            </li>
            <li key="credentials" style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Capture credentials:</label>
              <span>{campaignInfo.page.capture_credentials ? 'Yes' : 'No'}</span>
            </li>
            <li key="password" style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Capture password:</label>
              <span>{campaignInfo.page.capture_passwords ? 'Yes' : 'No'}</span>
            </li>
            {campaignInfo.page.capture_passwords && (
              <li key="redirect" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>On credentials submission, redirect to:</label>
                <a href={campaignInfo.page.redirect_url} target="_blank" rel="noopener noreferrer">{campaignInfo.page.redirect_url}</a>
              </li>
            )}
          </ul>
          <Divider align="center">
            <span className="p-tag">HTML</span>
          </Divider>
          <div
            style={{ maxHeight: '500px', overflowY: 'auto' }}
            dangerouslySetInnerHTML={{ __html: campaignInfo.page.html }}
          />
        </AccordionTab>
        <AccordionTab header={`${templateType === 'SMS' ? 'SMS Gateway' : 'SMTP'} Profile: ${campaignInfo.sendingProfile.name}`}>
          <label style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Configuration</label>
          <ul>
            {templateType === 'SMS' && (
              <li key="type" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Type:</label>
                <span>{campaignInfo.sendingProfile.type}</span>
              </li>
            )}
            <li key="shared-sending-profile" style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5rem' }}>Shared sending profile:</label>
              <span>{campaignInfo.sendingProfile.shared ? 'Yes' : 'No'}</span>
            </li>
          </ul>
        </AccordionTab>
        <AccordionTab header="Campaign Period">
          <div className="p-field">
            <label>Launch Date</label>
            <p>{campaignInfo.launch_date ? new Date(campaignInfo.launch_date).toLocaleString() : 'Now'}</p>
          </div>
          <Divider />
          <div className="p-field">
            <label>End Date</label>
            <p>{campaignInfo.send_by_date ? new Date(campaignInfo.send_by_date).toLocaleString() : 'Not set'}</p>
          </div>
        </AccordionTab>
        <AccordionTab header="Targets">
          <ListBox
            className="mb-2"
            options={campaignInfo.groups}
            optionLabel="name"
            itemTemplate={(option) => (
              <GroupListItem option={option} />
            )}
          />
          <label style={{ fontWeight: 'bold' }}>
            You will send this campaign to a total of
            {' '}
            {calculateTotalTargets(campaignInfo.groups)}
            {' '}
            people
          </label>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

CampaignResumeComponent.propTypes = {
  campaignInfo: PropTypes.object,
};

CampaignResumeComponent.defaultProps = {
  campaignInfo: null,
};

export default CampaignResumeComponent;
